import { render, staticRenderFns } from "./sales-table-card.vue?vue&type=template&id=5282f98a&scoped=true&"
import script from "./sales-table-card.vue?vue&type=script&lang=js&"
export * from "./sales-table-card.vue?vue&type=script&lang=js&"
import style0 from "./sales-table-card.vue?vue&type=style&index=0&id=5282f98a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5282f98a",
  null
  
)

component.options.__file = "sales-table-card.vue"
export default component.exports