<template>
  <div class="horizontal-bar-chart" :class="{'col-lg-12 col-12 col-xl-12': size == 'large', 'col-lg-6 col-12 col-xl-4': !size}">
    <div class="card">
      <div class="card-header">
        <h3 class="card-title">{{ title }}</h3>
      </div>
      <div class="card-body">
        <div
          class="bar-container-names"
        >
          <span class="names name-active">{{
            label
          }}</span>
          <span class="names">Ingressos</span>
          <span class="names">Vendas</span>
          <span class="names">Receita</span>
        </div>
        <div style="overflow: scroll; max-height: 232px;min-height: 230px;">
          <div
            v-for="(row, index) in data"
            :key="index"
            style="margin-top: 10px"
          >
            <div class="list-data">
              <span v-title="row.label" class="legend">{{ row.label || 'Não lançado' }}</span>
              <span class="wd">{{ row.countIngressos }}</span>
              <span class="wd">{{ row.countVendas }}</span>
              <span v-title="row.countReceita ? row.countReceita.toLocaleString('pt-BR', { maximumSignificantDigits: 3}) : 0" class="receita">
                {{ row.countReceita ? row.countReceita.toLocaleString('pt-BR', { maximumSignificantDigits: 3}) : 0 }}
              </span>
            </div>
            <div
              class="bar-percentage"
              :style="{
                width: (row.countReceita / data[0].countReceita) * 100 + '%',
              }"
            ></div>
          </div>
        </div>
        <div class="bar-container-names total-item">
          <p class="names name-active">TOTAL</p>
          <p class="total-item-bar" v-if="total">{{ total.totalIngressos }}</p>
          <p class="total-item-bar" v-if="total">{{ (total.totalVendas).toFixed(2) }}</p>
          <p class="total-item-bar" v-if="total">{{ (total.totalReceitas).toFixed(2) }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import c3 from "c3";
import { format } from "d3";
import $ from "jquery";
import Vue from 'vue';
import VTitle from 'v-title';

Vue.use(VTitle);
export default {
  name: "HorizontalBarChart",
  props: ["data", "total", "title", "label", "size"],
  data () {
    return {
    }
  },
  mounted() {
    c3.generate({
      bindto: "#chart-donut_" + this.key,
      size: {
        height: 150,
      },
      bar: {
        width: 40,
      },
      padding: {
        left: 60,
      },
      color: {
        pattern: ["#FABF62", "#ACB6DD"],
      },
      data: {
        x: "x",
        columns: [
          ["x", "Category1", "Category2"],
          ["value", 300, 400],
        ],

        type: "bar",

        color: function (inColor, data) {
          var colors = ["#FABF62", "#ACB6DD"];
          if (data.index !== undefined) {
            return colors[data.index];
          }

          return inColor;
        },
      },
      axis: {
        rotated: true,
        x: {
          type: "category",
        },
      },
      tooltip: {
        grouped: false,
      },
      legend: {
        show: false,
      },
    });
  },
  computed: {
    key() {
      return this.$vnode.key;
    },
  },
  methods: {
    filterAll() {
      this.chart.load({
        columns: [],
      });
    },
    filterWeb() {
      this.chart.load({
        columns: [],
      });
      this.chart.load({
        unload: [""],
      });
    },
    filterTicketoffice() {
      this.chart.load({
        columns: [],
      });
      this.chart.load({
        unload: [""],
      });
    },
  }
};
</script>

<style lang="scss">
.horizontal-bar-chart {
  .bar-container-names {
    display: flex;
    justify-content: space-between;
    background: #f5f7fb;
    .names {
      padding: 10px;
    }
    .name-active  {
      background: #4981cc; color: white
    }
  }
  .bar-percentage {
    background: #4981cc;
    height: 6px;
    border-radius: 0px 10px 10px 0px;
  }
  .list-data {
   justify-content: space-between; display: flex;
   .legend {
      text-align: start;
      min-width: 86px;
      width: 100px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
   }
   .receita {
     min-width: 74px; color: #4981cc; text-align: end;
   }
   .wd {
     min-width: 80px;
   }
  }
  .total-item {
    padding-right: 10px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    p {
      margin: 0;
      font-size: 14px;
    }
    p:last-child {
      color: #4981cc;
    }
  }
}
</style>
