<template>
  <div class="table-sales" :class="{'col-md-12 col-lg-12 col-sm-12': size == 'large', 'col-12 col-sm-6 col-lg-2' : size == 'small', 'col-lg-6 col-12 col-xl-6 mb-5': !size}">
    <div class="table-container">
      <div class="table-header">
        <p>{{ title }}</p>
      </div>
      <div class="table-section">
        <div class="table-title">
          <p v-for="(label, idx) in label" :key="idx">{{ label }}</p>
        </div>
        <div class="table-content" v-if="status === 'corporative'">
          <div class="item-table oper" v-for="(item, idx) in dataCorp" :key="idx">
            <p>{{ item.label }}</p>
            <p>{{ item.countAcessos }}</p>
            <p>{{ item.countCadastros }}</p>
            <p>{{ item.countPercentage }}</p>
          </div>
        </div>
        <div class="table-content" v-if="status === 'oper'">
          <div class="item-table oper" v-for="(item, idx) in dataSales" :key="idx">
            <p>{{ item.label }}</p>
            <p>{{ currency.format(item.sales) }}</p>
            <p>{{ currency.format(item.revenues) }}</p>
            <p>{{ item.tickets }}</p>
            <p>{{ item.margin }} %</p>
          </div>
        </div>
        <div class="table-content" style="height: 366px;" v-if="status === 'goal'">
          <div class="item-table goal" v-for="(item, idx) in dataGoals" :key="idx">
            <p>{{ item.label }}</p>
            <p>{{ item.op1 }}</p>
            <p>{{ item.op2 }}</p>
            <p>{{ item.op3 }}</p>
            <p>{{ item.op4 }}</p>
            <p>{{ item.op5 }}</p>
            <p>{{ item.op6 }}</p>
            <p v-if="item.op7">{{ item.op7 }}</p>
          </div>
        </div>
        <div class="table-total" v-if="status === 'oper'">
          <p>TOTAL</p>
          <p>{{ operTotal.sales ? currency.format(operTotal.sales) : 'R$ 0' }}</p>
          <p>{{ operTotal.revenues ? currency.format(operTotal.revenues) : 'R$ 0' }}</p>
          <p>{{ operTotal.tickets ? formatNumber.format(operTotal.tickets) : '0' }}</p>
          <p>{{ operTotal.margin }} %</p>
        </div>
        <div class="table-content" v-if="status === 'corp'">
          <div class="item-table corp" v-for="(item, idx) in corpTotal" :key="idx">
            <p style="width: 230px;">{{ item.empresa }}</p>
            <p style="text-align: left; width: 110px">{{ item.cidade }}</p>
            <p style="width: 90px">{{ item.quantidade }}</p>
          </div>
        </div>
        <div class="table-total" v-if="status === 'corp'">
          <p>TOTAL DE PEDIDOS</p>
          <p style="font-weight: bold; font-size: 15px;">{{ corpTotal.total || 0 }}</p>
        </div>
        <div class="table-total" v-if="status === 'corporative'">
          <p>TOTAL</p>
          <p>{{ corporativeTotal.acessos || 0 }}</p>
          <p>{{ corporativeTotal.cadastros || 0 }}</p>
          <p>{{ corporativeTotal.percentage || 0 }} %</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['dataSalesForCity', 'dataSales', 'dataGoals', 'dataCorp', 'title', 'label', 'status', 'size'],
  name: 'SalesTable',
  data () {
    return {
      key: 0,
      corpTotal: [],
      operTotal: {},
      corporativeTotal: {}
    }
  },
  mounted () {
    if (this.dataCorp && this.dataCorp.length > 0) {
      const totalAcessos = []
      const totalCadastros = []
      const totalPercentage = []

      this.dataCorp.forEach((e, i) => {
        totalAcessos.push(parseFloat(e.countAcessos))
        totalCadastros.push(parseFloat(e.countCadastros))
        totalPercentage.push(parseFloat(e.countPercentage))
      })

      this.corporativeTotal.acessos = totalAcessos.reduce((soma, i) => { return soma + i })
      this.corporativeTotal.cadastros = totalCadastros.reduce((soma, i) => { return soma + i })
      this.corporativeTotal.percentage = totalPercentage.reduce((soma, i) => { return soma + i }).toFixed(2)
    }

    if (this.dataSales && this.dataSales.length > 0) {
      const totalSales = []
      const totalRevenues = []
      const totalTickets = []

      this.dataSales.forEach((e, i) => {
        totalTickets.push(parseFloat(e.tickets))
        totalSales.push(parseFloat(e.sales))
        totalRevenues.push(parseFloat(e.revenues))
      })

      this.operTotal.sales = totalSales.reduce((soma, i) => { return soma + i })
      this.operTotal.revenues = totalRevenues.reduce((soma, i) => { return soma + i })
      this.operTotal.tickets = totalTickets.reduce((soma, i) => { return soma + i })
      this.operTotal.margin = (this.operTotal.revenues / this.operTotal.sales * 100).toFixed(2)
      return
    }

    if (this.dataSalesForCity) {
      if (this.dataSalesForCity.cidade) {
        this.dataSalesForCity.cidade.forEach(city => { this.corpTotal.push({ empresa: '', cidade: city, quantidade: ''})})
        this.dataSalesForCity.empresa.forEach((companie, idx) => { this.corpTotal[idx].empresa = companie.toLowerCase() })
        this.dataSalesForCity.qtes.forEach((qtde, idx) => { this.corpTotal[idx].quantidade = qtde })
        this.corpTotal.total = this.dataSalesForCity.total
        return
      }
    }
  },
  computed: {
    currency () {
      return Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' })
    },
    formatNumber () {
      return Intl.NumberFormat('pt-BR', { maximumSignificantDigits: 10 })
    }
  }
}
</script>

<style lang="scss" scoped>
.table-container {
  overflow: hidden;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 40, 100, 0.12);
  border-radius: 3px;
  .table-header {
    height: 3.5rem;
    border-bottom: 1px solid rgba(0, 40, 100, 0.12);
    display: flex;
    align-items: center;
    p {
      margin: 0 0 0 25px;
      font-size: 15px;
      font-weight: bold;
    }
  }
  .table-section {
    height: 322.5px;
    display: flex;
    flex-direction: column;
    .table-title {
      background: #f5f7fb;
      height: 3.5rem;
      border-bottom: 1px solid rgba(0, 40, 100, 0.12);
      display: flex;
      align-items: center;
      justify-content: space-around;
      p {
        width: 100px;
        margin: 0;
        font-size: 12px;
        text-transform: capitalize;
      }
      p:nth-child(1) {
        width: 202px;
        color: #4981cc;
        font-weight: bold;
        text-align: left;
        padding-left: 10px;
      }
    }
    .table-content {
      display: flex;
      flex-direction: column;
      height: 229px;
      overflow-y: auto;
      .item-table {
        padding: 7px 0;
        max-height: 35px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        p {
          width: 100px;
          font-size: 12px;
          margin: 0;
          text-transform: capitalize;
        }
        p:nth-child(1) {
          width: 202px;
          text-align: left;
          padding-left: 10px;
        }
      }
      .goal {
        p {
          font-size: 11px;
        }
      }
      .item-table:nth-child(odd) {
        background-color:#ffffff;
        border-bottom: 1px solid #4982cc25;
      }
      .item-table:nth-child(even) {
        background-color:#4982cc1c;
      }
    }
    .table-total {
      background: #4981cc;
      color: #ffffff;
      height: 38px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      p {
        font-size: 12px;
        margin: 0;
        width: 100px;
      }
      p:nth-child(1) {
        width: 174px;
        font-weight: bold;
        font-size: 12px;
        text-align: left;
        padding-left: 10px;
      }
    }
  }
}

@media (max-width: 500px) {
  .item-table.corp {
    p:nth-child(1) {
      padding-left: 3px !important;
      width: 255px !important;
    }
    p:nth-child(2) {
      font-size: 11px !important;
    }
    p {
      width: 90px !important;
    }
  }

  .table-title {
    p {
      font-size: 9px !important;
    }
  }

  .table-total {
    p {
      font-size: 11px !important;
    }
   p:nth-child(2) {
      margin-right: 5px !important;
    }
  }

  .item-table.oper {
    p {
      font-size: 10px !important;
    }
    p:nth-child(1) {
      padding-left: 3px !important;
      width: 265px !important;
    }
    p:nth-child(2) {
      margin-right: 5px !important;
    }
  }
}
</style>