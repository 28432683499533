<template>
<div :key="iddiv">
  <div>
    <div class="my-3 my-md-5">
      <div class="container" id="dashboard">
        <div class="page-header">
          <h1 class="page-title">
            Dashboard - Resumo Operacional
          </h1>
        </div>
        <div class="row">
          <form name="day">
            <div class="col-12 form-group filter-day">
              <div class="selectgroup selectgroup-pills">
                <label class="selectgroup-item">
                <input type="radio" name="icon-input" class="selectgroup-input" value="all" v-model="form.searchtype">
                <span class="selectgroup-button selectgroup-button-icon" @click="search('all');">Todos os Dias</span>
              </label>
                <label class="selectgroup-item">
                <input type="radio" name="icon-input" class="selectgroup-input" value="thirty" v-model="form.searchtype">
                <span class="selectgroup-button selectgroup-button-icon" @click="search('thirty');">30 Dias</span>
              </label>
                <label class="selectgroup-item">
                <input type="radio" name="icon-input" class="selectgroup-input" value="fifteen" v-model="form.searchtype">
                <span class="selectgroup-button selectgroup-button-icon" @click="search('fifteen');">15 Dias</span>
              </label>
                <label class="selectgroup-item">
                <input type="radio" name="icon-input" class="selectgroup-input" value="seven" v-model="form.searchtype">
                <span class="selectgroup-button selectgroup-button-icon" @click="search('seven');">7 Dias</span>
              </label>
                <label class="selectgroup-item">
                <input type="radio" name="icon-input" class="selectgroup-input" value="yesterday" v-model="form.searchtype">
                <span class="selectgroup-button selectgroup-button-icon" @click="search('yesterday');">Ontem</span>
              </label>
                <label class="selectgroup-item">
                <input type="radio" name="icon-input" class="selectgroup-input" value="today" v-model="form.searchtype">
                <span class="selectgroup-button selectgroup-button-icon" @click="search('today');">Hoje</span>
              </label>

                <label class="selectgroup-itx'em">
          <HotelDatePicker class="item-date-picker" format="DD-MM-YYYY" @confirm="customDateConfirmed()" v-model="customDate" type="date" :clearable="false" confirm range placeholder="Selecionar data customizada"
          ></HotelDatePicker>
              </label>
              </div>

            </div>
          </form>
        </div>
        <hr class="mt-0 pt-0">

        <!-- <half-circle-spinner v-if="!isLoaded" :size="60" color="#ddd"></half-circle-spinner> -->
        <template>
          <div class="row row-cards">
            <card-info :size="'small'" :value="dashboard.vendasGerais.value" title="VENDAS GERAIS" :isLoading="!dashboard.vendasGerais.loaded" :label="'R$ '"></card-info>
            <card-info :size="'small'" :value="dashboard.receitasGerais.value" title="RECEITAS GERAIS" :isLoading="!dashboard.receitasGerais.loaded" :label="'R$ '"></card-info>
            <card-info :size="'small'" :value="dashboard.vendas.value" title="VENDAS WEB" :isLoading="!dashboard.vendas.loaded" :label="'R$ '"></card-info>
            <card-info :size="'small'" :value="dashboard.receita.value" title="RECEITA WEB" :isLoading="!dashboard.receita.loaded" :label="'R$ '"></card-info>
            <card-info :size="'small'" :value="dashboard.usuariosCadastrados.value" title="CADASTRO GERAL" :isLoading="!dashboard.usuariosCadastrados.loaded"></card-info>
          </div>

          <div class="row row-cards">
            <card-info :size="'small'" :value="dashboard.meta.value" title="META MENSAL" :isLoading="!dashboard.meta.loaded" :label="'R$ '"></card-info>
            <card-info :size="'small'" :value="dashboard.porcentagemMeta.value" title="META MENSAL" :isLoading="!dashboard.porcentagemMeta.loaded" :label="'% '"></card-info>
            <card-info :size="'small'" :value="dashboard.ticketMedio.value" title="TICKET MÉDIO" :isLoading="!dashboard.ticketMedio.loaded" :label="'R$ '"></card-info>
            <card-info :size="'small'" :value="dashboard.ingressoMedio.value" title="INGRESSO MÉDIO" :isLoading="!dashboard.ingressoMedio.loaded" :label="'R$ '"></card-info>
            <card-info :size="'small'" :value="dashboard.saldoEprepag.value" title="SALDO E-PREPAG" :isLoading="!dashboard.saldoEprepag.loaded" :label="'R$ '"></card-info>
          </div>

          <div class="row row-cards">
            <div class="col-10 col-sm-4 col-lg-2">
              <large-card-info :value="dashboard.usuarios.value" title="USUÁRIOS CADASTRADOS" :isLoading="!dashboard.usuarios.loaded"></large-card-info>
            </div>
            <div class="col-10 col-sm-4 col-lg-2">
              <large-card-info :value="dashboard.acessos.value" title="ACESSOS ANALYTICS" :isLoading="!dashboard.acessos.loaded"></large-card-info>
            </div>
            <div class="col-10 col-sm-4 col-lg-2">
              <large-card-info :value="dashboard.facebook.value" title="CURTIDAS FACEBOOK" :isLoading="!dashboard.facebook.loaded"></large-card-info>
            </div>
            <div class="col-10 col-sm-4 col-lg-2">
              <large-card-info :value="dashboard.instagram.value" title="SEGUIDORES INSTAGRAM" :isLoading="!dashboard.instagram.loaded"></large-card-info>
            </div>
            <div class="col-10 col-sm-4 col-lg-2">
              <large-card-info :value="dashboard.twitter.value" title="SEGUIDORES TWITTER" :isLoading="!dashboard.twitter.loaded"></large-card-info>
            </div>
          </div>


          <div class="row row-cards">
            <card-info :showCustomLoad="true" :isLoading="dashboard.transacoes.loaded" :title="'TRANSAÇÕES WEB'" :value="dashboard.transacoes.value" :size="'small'" :percentage="''" :status="''"></card-info>
            <card-info :showCustomLoad="true" :isLoading="dashboard.apresentacao.loaded" :title="'APRESENTAÇÕES'" :value="dashboard.apresentacao.value" :size="'small'" :percentage="''" :status="''"></card-info>
            <card-info :showCustomLoad="true" :isLoading="dashboard.eventos.loaded" :title="'EVENTOS'" :value="dashboard.eventos.value" :size="'small'" :percentage="''" :status="''"></card-info>
            <card-info :showCustomLoad="true" :isLoading="dashboard.pedidos.loaded" :title="'PEDIDOS WEB'" :value="dashboard.pedidos.value" :size="'small'" :percentage="''" :status="''"></card-info>
            <card-info :showCustomLoad="true" :isLoading="dashboard.ingressos.loaded" :title="'INGRESSOS WEB'" :value="dashboard.ingressos.value" :percentage="''" :size="'small'" :status="''"></card-info>
          </div>
    
          <div class="row">
            <horizontal-bar-chart :label="'SITES'" :title="'RANKING DE SITES'" :data="rankingSites.items" :total="rankingSites.total"></horizontal-bar-chart>
            <horizontal-bar-chart :label="'EVENTOS'" :title="'RANKING DE EVENTOS'" :data="rankingEvents.items" :total="rankingEvents.total"></horizontal-bar-chart>
            <horizontal-bar-chart :label="'EMPRESAS'" :title="'RANKING DE EMPRESAS'" :data="rankingCompanies.items" :total="rankingCompanies.total"></horizontal-bar-chart>
          </div>

          <div class="row row-cards" :key="refresh">
            <sales-table-card :label="['CANAL', 'VENDAS', 'RECEITAS', 'INGRESSOS', '% MARGEM']" :title="'VENDAS POR CANAL RESUMIDO'" :dataSales="salesForChannel" :status="'oper'"></sales-table-card>
            <sales-table-card :label="['MÊS', 'VENDAS', 'RECEITAS', 'INGRESSOS', '% MARGEM']" :title="'VENDAS MÊS A MÊS'" :dataSales="salesMonthToMonth" :status="'oper'"></sales-table-card>
          </div>

          <div class="row">
            <sales-table-card :label="['MÊSES', 'ACESSOS', 'CADASTROS', '%']" :title="'CORPORATIVO'" :dataCorp="mockDataCorp" :status="'corporative'"></sales-table-card>
            <pie-chart :customClass="'col-12 col-lg-6 col-xl-6'" :key="'V'+vendasPorCanal.key" :title="'Vendas por canal'" :data="vendasPorCanal.data"></pie-chart>
          </div>

          <div class="row">
            <pie-chart-with-filter :key="refresh" :customClass="'col-12 col-lg-6 col-xl-6'" :hasFilter="true" :title="'Vendas por forma de pagamento'" :data="vendasPorPagamento"></pie-chart-with-filter>
            <pie-chart  :customClass="'col-12 col-lg-6 col-xl-6'" :key="dashboard.pedidos.value" :title="'Ocupação'" :data="[['Disponível', 10], ['Gratuíto', 3], ['Reservado', 14], ['Vendido', 100]]"></pie-chart>
          </div>
        
          <div class="row">
            <chart-bar-stacked :key="dashboard.vendas.loaded" :customClass="'col-12 col-lg-6 col-xl-6'" size="large" :title="'Vendas por horário'" :data="{ticketoffice: [ 'ticketoffice', 5, 10], web: [ 'web', 10, 7 ] }"></chart-bar-stacked>
          </div>
        </template>
      </div>
    </div>

  </div>
</div>
</template>

<script>
// muitas funções não são utilizadas ainda (está com dados mockados!!!)
import { userService } from '../../../components/common/services/user';
import { eventService } from "../../../components/common/services/event";
import { SemipolarSpinner } from 'epic-spinners'
import VueResource from "vue-resource";
import config from "@/config";
import Vue from "vue";
import cardInfo from "@/views/dashboard/new-card-info";
import largeCardInfo from "@/views/dashboard/large-card-info";
import pieChart from "@/views/dashboard/pie-chart";
import horizontalBarChart from "@/views/dashboard/horizontal-bar-chart";
import pieChartWithFilter from "@/views/dashboard/pie-chart-with-filter";
import chartBarStacked from "@/views/dashboard/chart-bar-stacked";
import overtimeChart from "@/views/dashboard/overtime-chart";
import HotelDatePicker from 'vue2-datepicker';
import SalesTableCard from '../sales-table-card.vue';
import { HollowDotsSpinner } from 'epic-spinners'
import {  HalfCircleSpinner } from 'epic-spinners'
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { func } from "@/functions";
import { dashboardService } from "../../../components/common/services/dashboard";
import 'vue2-datepicker/index.css';

const moment = extendMoment(Moment);

export default {
  mixins: [func],
  data() {
    return {
      mockDataCorp: [
        {
            "label": "JAN",
            "countAcessos": 0,
            "countCadastros": 0,
            "countPercentage": 0
        },
        {
            "label": "FEV",
            "countAcessos": 0,
            "countCadastros": 0,
            "countPercentage": 0
        },
        {
            "label": "MAR",
            "countAcessos": 0,
            "countCadastros": 0,
            "countPercentage": 0
        },
        {
            "label": "ABR",
            "countAcessos": 0,
            "countCadastros": 0,
            "countPercentage": 0
        },
        {
            "label": "MAI",
            "countAcessos": 0,
            "countCadastros": 0,
            "countPercentage": 0
        },
        {
            "label": "JUN",
            "countAcessos": 0,
            "countCadastros": 0,
            "countPercentage": 0
        },
        {
            "label": "JUL",
            "countAcessos": 0,
            "countCadastros": 0,
            "countPercentage": 0
        },
        {
            "label": "AGO",
            "countAcessos": 0,
            "countCadastros": 0,
            "countPercentage": 0
        },
        {
            "label": "SET",
            "countAcessos": 0,
            "countCadastros": 0,
            "countPercentage": 0
        },
        {
            "label": "OUT",
            "countAcessos": 0,
            "countCadastros": 0,
            "countPercentage": 0
        },
        {
            "label": "NOV",
            "countAcessos": 0,
            "countCadastros": 0,
            "countPercentage": 0
        },
        {
            "label": "DEZ",
            "countAcessos": 0,
            "countCadastros": 0,
            "countPercentage": 0
        }
      ],
      refresh: 0,
      isLoaded: false,
      datepickerHidden: true,
      rankingSites: {},
      rankingEvents: {},
      rankingCompanies: {},
      salesForChannel: [],
      salesMonthToMonth: [],
      customDate: '',
      datepicker: {
        id: 1,
        format: 'DD/MM/YYYY',
        hoveringTooltip: true,
        displayClearButton: false,
        startDate:  new Date('2020-01-01'),
        endDate: new Date('2055-01-01'),
        ptBr: {
          night: 'Dia',
          nights: 'Dias',
          'day-names': ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
          'check-in': 'Início',
          'check-out': 'Fim',
          'month-names': ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
        },
      },
      iddiv: 1,
      processing: false,
      form: {
        searchtype: 'today',
        id_base: '',
        id_evento: '',
        date: '',
        hour: '',
        selectedDate: {
          start: moment(new Date()).format("DD/MM/YYYY"),
          end: '',
        }
      },
      selects: {
        base: [],
        events: [],
        days: [],
        hours: []
      },
      vendasPorPagamento: {
        all: [],
        ticketoffice: [],
        web: []
      },
      vendasPorCanal: {
        data: [],
        loaded: false,
        key: 0
      },
      dashboard: {
        twitter: {
          loaded: false,
          value: 0
        },
        facebook: {
          loaded: false,
          value: 0
        },
        instagram: {
          loaded: false,
          value: 0
        },
        apresentacao: {
          loaded: false,
          value: 0
        },
        acessos: {
          loaded: false,
          value: 0,
          mappedKey: ''
        },
        usuarios: {
          loaded: false,
          value: 0
        },
        transacoes: {
          loaded: false,
          value: 0
        },
        eventos: {
          loaded: false,
          value: 0,
          mappedKey: ''
        },
        pedidos: {
          loaded: false,
          value: 0,
          mappedKey: ''
        },
        ingressos: {
          loaded: false,
          value: 0,
          mappedKey: ''
        },
        vendas: {
          loaded: false,
          value: 0,
          mappedKey: ''
        },
        receita: {
          loaded: false,
          value: 0,
          mappedKey: ''
        },
        ingressoMedio: {
          loaded: false,
          value: 0
        },
        ticketMedio: {
          loaded: false,
          value: 0,
          mappedKey: ''
        },
        meta: {
          loaded: false,
          value: 0
        },
        porcentagemMeta: {
          loaded: false,
          value: 0
        },
        sites: {
          loaded: false,
          value: 0,
          mappedKey: ''
        },
        eventos: {
          loaded: false,
          value: 0,
          mappedKey: ''
        },
        empresas: {
          loaded: false,
          value: 0
        },
        vendasGerais: {
          loaded: false,
          value: 0
        },
        receitasGerais: {
          loaded: false,
          value: 0
        },
        usuariosCadastrados: {
          loaded: false,
          value: 0
        },
        saldoEprepag: {
          loaded: false,
          value: 0
        }
      }
    }
  },
  components: {
    cardInfo,
    pieChart,
    largeCardInfo,
    horizontalBarChart,
    overtimeChart,
    chartBarStacked,
    pieChartWithFilter,
    HotelDatePicker,
    HollowDotsSpinner,
    HalfCircleSpinner,
    SalesTableCard
  },
  created() {
    this.hideWaitAboveAll();
    // this.closer();
    //  this.$refs.dtpicker.hideDatepicker();
  },
  async mounted () {
    await this.search('today')
  },
  methods: {
    async customDateConfirmed() {
      if (this.customDate.length > 1 && this.customDate[0]) {
        this.form.searchtype = ''
        if (moment(this.customDate[0]).isValid()) {
          this.form.selectedDate.start = moment(this.customDate[0]).format("YYYY-MM-DD")
        }
        if (moment(this.customDate[1]).isValid()) {
          this.form.selectedDate.end = moment(this.customDate[1]).format("YYYY-MM-DD")
        }
        await this.getDashboard()
      }
    },
    async endchanged(date) {
      // this.form.selectedDate.end = moment(date).isValid() ? moment(date).add(1,'days').format("DD/MM/YYYY") : '';
      // this.checkifpersonalized();
      this.isLoaded = false
      this.form.selectedDate.end = moment(date).isValid() ? moment(date).format("YYYY-MM-DD") : '';
      await this.getDashboard()
      this.isLoaded = true
      if (date) {
        this.form.searchtype = ''
      }
    },
    checkifpersonalized() {
      // debugger;
      Vue.nextTick().then(response => {
        if (this.form.selectedDate.start != '' && this.form.selectedDate.end != '') {
          // this.form.searchtype = '';
          this.search('');
        }
        else {
          if (this.form.selectedDate.start == '' && this.form.selectedDate.end == '' && this.form.searchtype == '') {
            this.search('today');
          }
        }
      });
    },
    toggleDate() {
      this.$refs.dtpicker.hideDatepicker();
      this.$refs.dtpicker.toggleDatepicker();
    },
    async getDashboard () {
      // this.overtimeData = []
      this.clear()
      this.isLoaded = false;

      let filter = {
          start: this.form.selectedDate.start || moment(new Date('2021-01-01')).format("YYYY-MM-DD"),
          end: this.form.selectedDate.end
        }

        if (!filter.start && !filter.end) {
          this.form.searchtype = 'today'
        }

        dashboardService.getTwitter().then(result => {
          if (result) {
            this.dashboard.twitter.value = result['SEGUIDORES TWITTER'] || 0
            this.cardsDone(['twitter'])
          }
        })

        dashboardService.getGeneralRegister().then(result => {
          if (result) {
            this.dashboard.usuariosCadastrados.value = result.dados.total || 0
            this.cardsDone(['usuariosCadastrados'])
          }
        })

        dashboardService.getEprepag().then(result => {
          if (result) {
            this.dashboard.saldoEprepag.value = result.saldo || 0
            this.cardsDone(['saldoEprepag'])
          }
        })

        dashboardService.getGeneralSalesAndRevenue(filter).then(result => {
          if (result) {
            this.dashboard.vendasGerais.value = result.dados.VendaBruta[0] || 0
            this.dashboard.receitasGerais.value = result.dados.Receita[0] || 0
            this.cardsDone(['vendasGerais', 'receitasGerais'])
          }
        })

        dashboardService.getSumaryChannel(filter).then(result => {
          if (result) {
            const MONTHS_MAP = {
              Janeiro: 1,
              Fevereiro: 2,
              Marco: 3,
              Abril: 4,
              Maio: 5,
              Junho: 6,
              Julho: 7,
              Agosto: 8,
              Setembro: 9,
              Outubro: 10,
              Novembro: 11,
              Dezembro: 12,
            }

            const salesChannel = result.debug.CanalResumido.resultCanalResumido.map((base) => {
              const [mes, ano] = base.MesAno.split('/')
              return {
                ano,
                month: MONTHS_MAP[mes],
                label: `${base.MesAno} - ${base.Base_dados}`,
                sales: parseFloat(base.Venda_Bruta),
                revenues: base.Receita,
                tickets: base.TotalIngresso,
                margin: ((base.Receita / parseFloat(base.Venda_Bruta)) * 100).toFixed(2),
              }
            })
            // sort by year, month and sales
            salesChannel.sort((a, b) => {
              if (a.ano === b.ano) {
                if (a.month === b.month) {
                  if (a.sales === b.sales) {
                    return 0
                  }
                  return a.sales > b.sales ? 1 : -1
                }
                return a.month - b.month
              } else {
                return a.ano - b.ano
              }
            })

            this.salesForChannel = salesChannel.reverse()
            this.refresh++
          }
        })

        dashboardService.getSalesMonthByMonth(filter).then(result => {
          if (result) {
            const salesMonth = []
            result.dados.mes_descr.forEach((month, idx) => {
              if (month !== 'Total') {
                salesMonth.push({
                label: `${month} / ${result.dados.ano_venda[idx]}`,
                sales: result.dados.total_venda[idx],
                revenues: result.dados.total_receita[idx].toFixed(2),
                tickets: result.dados.qtd_ingressos[idx],
                margin: ((result.dados.total_receita[idx] / result.dados.total_venda[idx]) * 100).toFixed(2)
                })
              }
            })
            this.salesMonthToMonth = salesMonth
            this.refresh++
          }
        })

        dashboardService.getSalesByPaymentMethods(this.getLoggedId(), filter).then(result => {
          if (result) {
            const salesForPayment = Object.entries(result.dados)
            this.vendasPorPagamento.all = salesForPayment
            this.vendasPorPagamento.ticketoffice = salesForPayment
          }
        })

        dashboardService.getSummaryCards(this.getLoggedId(), filter).then((dashCards) => {
          // dashCards = await dashCards
          if (dashCards && (dashCards.status === true || dashCards.dados)) {
            this.dashboard.eventos.loaded = true
            this.dashboard.apresentacao.value = dashCards.dados.qntApresentacao.total
            this.dashboard.vendas.value = dashCards.dados.totalPedidos.total
            this.dashboard.usuarios.value = dashCards.dados.qntTotalUsuarios.total
            this.dashboard.transacoes.value = dashCards.dados.qntTotalTransacoes.total
            this.dashboard.ticketMedio.value = (this.dashboard.vendas.value / this.dashboard.transacoes.value || 0)
            this.dashboard.pedidos.value = dashCards.dados.qntTotalPedidos.total
            this.dashboard.ingressos.value = dashCards.dados.qntIngressos.total
            this.dashboard.ingressoMedio.value = (this.dashboard.vendas.value / this.dashboard.ingressos.value || 0)
            this.dashboard.eventos.value = dashCards.dados.qntEventos.total
            this.cardsDone(['eventos', 'apresentacao', 'ingressoMedio', 'ticketMedio', 
            'receita', 'usuarios', 'transacoes', 'pedidos', 'ingressos', 'vendas'])
          }
          dashboardService.getMeta().then((value) => {
            if (value && value.status === true) {
              this.dashboard.receita.value = (this.dashboard.vendas.value * value.receita) / 100
              this.dashboard.meta.value = value.meta.replace('.', '')
              this.dashboard.porcentagemMeta.value = ((this.dashboard.receitasGerais.value / this.dashboard.meta.value) * 100).toFixed(2)
              this.dashboard.instagram.value = parseInt(Object.entries(value)[10][1]) || 0
              this.dashboard.instagram.loaded = true
              this.dashboard.meta.loaded = true
              this.dashboard.porcentagemMeta.loaded = true
              dashboardService.getRankingOfSites(
                  this.getLoggedId(),
                  { 
                    start: moment(filter.start).format("DD-MM-YYYY"), 
                    end: filter.end ? moment(filter.end).format("DD-MM-YYYY") : ''
                  }
                ).then((ranking) => {
                  if (ranking && ranking.debug && ranking.debug.RankingSites && ranking.debug.RankingSites.resultRankingSites) {
                    let arrayOfValues = ranking.debug.RankingSites.resultRankingSites
                    const total = {
                      totalIngressos: [],
                      totalVendas: [],
                      totalReceitas:  []
                    }
                    arrayOfValues = arrayOfValues.map(item => {
                      const valorVenda = parseFloat(item.valorTotalPedidos)
                      const receita = item.valorTotalReceita
                      total.totalIngressos.push(item.qntIngresso)
                      total.totalVendas.push(valorVenda)
                      total.totalReceitas.push(receita)
                      return {
                        label: item.uniquename_partner,
                        countIngressos: parseInt(item.qntIngresso),
                        countVendas: item.valorTotalPedidos,
                        countReceita: receita,
                      } 
                    })
                    arrayOfValues.sort((a, b) => parseFloat(a.countVendas) < parseFloat(b.countVendas) ? 1 : -1)
                    total.totalIngressos = total.totalIngressos.reduce((soma, i) => { return soma + i }),
                    total.totalVendas = total.totalVendas.reduce((soma, i) => { return soma + i }),
                    total.totalReceitas = total.totalReceitas.reduce((soma, i) => { return soma + i }),
                    this.rankingSites.items = arrayOfValues
                    this.rankingSites.total = total
                  }
              })

              dashboardService.getRankingOfEvents(
                  this.getLoggedId(),
                  { 
                    start: moment(filter.start).format("DD-MM-YYYY"), 
                    end: filter.end ? moment(filter.end).format("DD-MM-YYYY") : ''
                  }
                ).then((ranking) => {
                  if (ranking && ranking.debug && ranking.debug.RankingEventos && ranking.debug.RankingEventos.resultRankingEventos) {
                    let arrayOfValues = ranking.debug.RankingEventos.resultRankingEventos
                    const totalEvents = {
                      totalIngressos: [],
                      totalVendas: [],
                      totalReceitas:  []
                    }
                    arrayOfValues = arrayOfValues.map(item => {
                      const valorVenda = parseFloat(item.valorTotalPedido)
                      const receita = item.valorTotalReceita
                      totalEvents.totalIngressos.push(item.qntIngresso)
                      totalEvents.totalVendas.push(valorVenda)
                      totalEvents.totalReceitas.push(receita)
                      return { 
                        label: item.ds_evento,
                        countIngressos: parseInt(item.qntIngresso),
                        countVendas: item.valorTotalPedido,
                        countReceita: receita
                      } 
                    })
                    arrayOfValues.filter((item, idx) => {
                      arrayOfValues.forEach((item2, index) => {
                        if (item.label === item2.label && idx !== index) {
                          item.countIngressos = item.countIngressos + item2.countIngressos
                          item.countReceita = item.countReceita + item2.countReceita
                          item.countVendas = (parseFloat(item.countVendas) + parseFloat(item2.countVendas)).toFixed(2)
                          arrayOfValues.splice(index, 1)
                        }
                      })
                    })
                    arrayOfValues.sort((a, b) => parseFloat(a.countVendas) < parseFloat(b.countVendas) ? 1 : -1)
                    totalEvents.totalIngressos = totalEvents.totalIngressos.reduce((soma, i) => { return soma + i }),
                    totalEvents.totalVendas = totalEvents.totalVendas.reduce((soma, i) => { return soma + i }),
                    totalEvents.totalReceitas = totalEvents.totalReceitas.reduce((soma, i) => { return soma + i }),
                    this.rankingEvents.items= arrayOfValues
                    this.rankingEvents.total = totalEvents
                  }
              })

              dashboardService.getRankingOfCompanies(
                  this.getLoggedId(),
                  { 
                    start: moment(filter.start).format("DD-MM-YYYY"), 
                    end: filter.end ? moment(filter.end).format("DD-MM-YYYY") : ''
                  }
                ).then((ranking) => {
                  if (ranking && ranking.debug && ranking.debug.RankingEmpresas && ranking.debug.RankingEmpresas.resultRankingEmpresas) {
                    let arrayOfValues = ranking.debug.RankingEmpresas.resultRankingEmpresas
                    const totalRanking = {
                      totalIngressos: [],
                      totalVendas: [],
                      totalReceitas:  []
                    }
                    arrayOfValues = arrayOfValues.map(item => {
                      const valorVenda = parseFloat(item.valorTotalPedido)
                      const receita = item.valorTotalReceita
                      totalRanking.totalIngressos.push(item.qntIngresso)
                      totalRanking.totalVendas.push(valorVenda)
                      totalRanking.totalReceitas.push(receita)
                      return { 
                        label: item.name,
                        countIngressos: parseInt(item.qntIngresso),
                        countVendas: item.valorTotalPedido,
                        countReceita: receita
                      } 
                    })
                    arrayOfValues.filter((item, idx) => {
                      arrayOfValues.forEach((item2, index) => {
                        if (item.label === item2.label && idx !== index) {
                          item.countIngressos = item.countIngressos + item2.countIngressos
                          item.countVendas = (parseFloat(item.countVendas) + parseFloat(item2.countVendas)).toFixed(2)
                          item.countReceita = parseFloat(item.countReceita.toFixed(2) + item2.countReceita.toFixed(2))
                          arrayOfValues.splice(index, 1)
                        }
                      })
                    })
                    arrayOfValues.sort((a, b) => parseFloat(a.countVendas) < parseFloat(b.countVendas) ? 1 : -1)
                    totalRanking.totalIngressos = totalRanking.totalIngressos.reduce((soma, i) => { return soma + i }),
                    totalRanking.totalVendas = totalRanking.totalVendas.reduce((soma, i) => { return soma + i }),
                    totalRanking.totalReceitas = totalRanking.totalReceitas.reduce((soma, i) => { return soma + i }),
                    this.rankingCompanies.items = arrayOfValues
                    this.rankingCompanies.total = totalRanking
                  }
              })
            }
          }).catch(err => {
            console.log('err', err)
            this.dashboard.meta.value = 0
            this.dashboard.meta.loaded = true
          })
        })

        dashboardService.getFacebookAcessos().then((value) => {
          if (value && value.id) {
            this.dashboard.facebook.value = value.fan_count
            this.dashboard.facebook.loaded = true
          }
        }).catch(err => {
          this.dashboard.facebook.value = 0
          this.dashboard.facebook.loaded = true
        })


        dashboardService.getVendasPorCanal(filter).then((value) => {
          this.vendasPorCanal.data = []
          if (value && value.dados) {
            this.vendasPorCanal.data.push(['Vendas Físicas', value.dados.vendaFisica], ['Vendas Web', value.dados.vendaWeb])
            this.vendasPorCanal.loaded = true
            this.vendasPorCanal.key++
          }
        }).catch(err => {
          this.vendasPorCanal.data = []
          this.vendasPorCanal.loaded = true
          this.vendasPorCanal.key++
        })

        dashboardService.getGoogleUserQtd(
            { 
              start: filter.start, 
              end: filter.end || moment(new Date()).format("YYYY-MM-DD")
            }).then((value) => {
          if (value && value.status === true) {
            this.dashboard.acessos.value = value.totalUsers
            this.dashboard.acessos.loaded = true
          }
          this.isLoaded = true;
        }).catch(err => {
          this.dashboard.acessos.value = 0
          this.dashboard.acessos.loaded = true
        })
    },
    cardsDone (array) {
      array.forEach(key => {
        this.dashboard[key].loaded = true
      })
    },
    clear() {
      Object.keys(this.dashboard).forEach(key => {
        this.dashboard[key].loaded = false
        this.dashboard[key].value = 0
      })
    },
    async search(type) {
      this.isLoaded = false
      this.form.searchtype = type
      let startDate = ''
      let endDate = ''
      this.customDate = ''
      this.form.selectedDate.start = ''
      this.form.selectedDate.end = ''
      switch (this.form.searchtype) {
        case 'fifteen':
            startDate = moment().subtract(15, "days");
            endDate = moment();
          break
        case 'seven':
            startDate = moment().subtract(7, "days");
            endDate = moment();
          break
        case 'yesterday':
            startDate = moment().subtract(1, "days")
          break
        case 'thirty':
            startDate = moment().subtract(30, "days");
            endDate = moment();
          break
        case 'today':
            startDate = moment()
          break
        case 'all':
            startDate = '';
            endDate = moment();
          break
        case '':
            startDate = ''
          break
      }

      if (startDate) {
        this.form.selectedDate.start =  startDate.format("YYYY-MM-DD")
      }
      if (endDate) {
        this.form.selectedDate.end =  endDate.format("YYYY-MM-DD")
      }
      await this.getDashboard()
      this.isLoaded = true
    }
  }
}
</script>

<style lang="scss">
#dashboard {
  .item-date-picker {
    .mx-input { 
      height: 28px;
    }
  }

  .row-cards {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .datepicker__dummy-wrapper {
    border-radius: 12px;
    height: 26px !important;

    .datepicker__input {
      font-size: 14px;
      color: rgb(154, 160, 172);
      /* padding-right: 0; */
      /* padding-left: 10px; */
      font-size: 11px;
      color: #9aa0ac;
      line-height: 2.2;
      height: 26px;
    }

    .datepicker__clear-button {
      margin: 8px -2px 0 0;
      font-size: 14px;
      height: 22px !important;
    }

    .datepicker__input:first-child {
      /* text-indent: 22px; */
      /* padding-left: 20px; */
      /* padding-right: 80px; */
      background: transparent url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI4IiBoZWlnaHQ9IjE4IiB2aWV3Qm94PSIwIDAgOCAxOCI+CiAgICA8cGF0aCBmaWxsPSIjOTU5OUFBIiBmaWxsLXJ1bGU9Im5vbnplcm8iIGQ9Ik0uMTE5LjcxOGw3LjE1OCA3LjQwNy0uMDMzLS41NTEtNi43MzcgOC44ODlhLjQyNS40MjUgMCAwIDAgLjA4LjU5My40Mi40MiAwIDAgMCAuNTktLjA4bDYuNzM3LTguODg5YS40MjUuNDI1IDAgMCAwLS4wMzMtLjU1MUwuNzIzLjEyOEEuNDIuNDIgMCAwIDAgLjEyOC4xMmEuNDI1LjQyNSAwIDAgMC0uMDA5LjU5OHoiLz4KPC9zdmc+Cg==) no-repeat 100%/5px !important;
    }
  }

  .datepicker__wrapper {
    background: transparent url('/assets/icons/edit.svg') no-repeat 14px/13px !important;
    height: 22px !important;
  }

  .datepicker__clear-button svg {
    width: 10px !important;
    top: -10px !important;
  }

  @media (max-width: 800px) {

  .input-group-prepend, .input-group-append, .input-group-btn, .input-group-text {
    margin-bottom: 2px;
    margin-top: 2px;
    font-weight: bold;
    padding-left: 0;
    background: transparent;
    border: none;
  }

  .input-group {
    display: block;
  }

  select {
    border-radius: 4px;
    width: 50%;
    display: flex;
  }
  }

    .input-group-prepend, .input-group-append, .input-group-btn, .input-group-text { 
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    font-weight: bold;
    color: hsl(0, 0%, 13%);

    }
  select {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    color: hsl(0,0%,29%);
  }


}
</style>
