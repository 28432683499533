<template>
  <div class="col-lg-8 col-12 col-xl-12">
    <div class="card">
      <div class="card-header d-flex">
        <h3 class="card-title pr-3">{{ title }}</h3>
      </div>
      <div class="card-body">
        <div id="overtime-chart" style=""></div>
      </div>
    </div>
  </div>
</template>

<script>
import c3 from "c3";
import { format } from "d3";
import $ from "jquery";

export default {
  name: "OvertimeChart",
  computed: {},
  methods: {
    filterAll() {
      this.chart.load({
        columns: [this.originalValues.web, this.originalValues.ticketoffice],
      });
    },
    filterWeb() {
      this.chart.load({
        columns: [this.originalValues.web],
      });
      this.chart.load({
        unload: ["ticketoffice"],
      });
    },
    filterTicketoffice() {
      this.chart.load({
        columns: [this.originalValues.ticketoffice],
      });
      this.chart.load({
        unload: ["web"],
      });
    },
  },
  props: {
    dados: Array,
    title: String,
  },
  data() {
    return {
      chart: null,
      originalValues: null,
    };
  },
  mounted() {
    if (this.dados.length <= 0) {
      return false;
    }
    this.chart = c3.generate({
      bindto: "#overtime-chart", // id of chart wrapper
      data: {
        x: 'x',
        xFormat: '%d-%m-%Y',
        columns: this.dados,
        type: "line", // default type of chart
        onclick: undefined,
        colors: {
          web: "#467fcf",
          ticketoffice: "rgb(244,111,155)",
          ticketoffice: "rgb(244,111,155)",
          ticketoffice: "rgb(244,111,155)",
          ticketoffice: "rgb(244,111,155)",
        },
      },
      axis: {
        x: {
          type: "timeseries",
          tick: {
              format: '%d-%m-%Y'
          }
        },
      }
    });

    // }
    this.chart;
    this.chart.resize({ bar: 20 });

    this.originalValues = this.data;
  },
};
</script>
