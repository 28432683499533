import Vue from 'vue';
import moment from 'moment';
import VueResource from "vue-resource";
import config from '@/config';

Vue.use(VueResource);

config.setapikey();

export const dashboardService = {
  closer,
  getMeta,
  getAccess,
  getCompanies,
  purchasevalues,
  getSummaryCards,
  getGeneralSalesAndRevenue,
  getEmployees,
  getTwitter,
  getSalesByPaymentMethods,
  getSumaryChannel,
  getSalesMonthByMonth,
  getEprepag,
  getGeneralRegister,
  getSalesForCity,
  getPush,
  getGoogleUserQtd,
  purchasebyboleto,
  getRankingOfSites,
  purchasebychannel,
  getVendasPorCanal,
  getRankingOfEvents,
  getFacebookAcessos,
  purchaseoccupation,
  purchasebytimetable,
  getRankingOfCompanies,
  purchasebypaymenttype,
  getDataDashConsultores,
  getConsultores
}

function getCompanies(loggedId, showInativo) {
    let url = config.api + `/v1/dashboard/getEmpresa?loggedId=${loggedId}`;
    if (showInativo) {
        url += `&showInativo=${showInativo}`
    }
    return new Promise(
        function (resolve, reject) {
            Vue.http.get(url).then(res => {
                resolve(res.body);
            }, err => {
                reject({
                    error: true,
                    msg: err
                });
            });
        }
    );
}

function getAccess(loggedId, filter) {
    let url = config.api + `/v1/dashboard/puxaracessos?loggedId=${loggedId}&code=${filter.code}&name=${filter.name}`;
    if (filter.start) {
        url = `${url}&dtInicial=${filter.start}`
    }
    if (filter.end) {
        url = `${url}&dtFinal=${filter.end}`
    }
    return new Promise(
        function (resolve, reject) {
            Vue.http.get(url).then(res => {
                resolve(res.body);
            }, err => {
                reject({
                    error: true,
                    msg: err
                });
            });
        }
    );
}

function getTwitter () {
    let url = config.api + `/v1/dashboard/meta.php`

    return new Promise(
        function (resolve, reject) {
            Vue.http.get(url).then(res => {
                resolve(res.body);
            }, err => {
                reject({
                    error: true,
                    msg: err
                })
            });
        }
    );
}

function getSummaryCards(loggedId, filter) {
    let url = config.api + `/v1/dashboard/operacional?loggedId=${loggedId}`;
    if (filter.start) {
        url = `${url}&dtInicial=${filter.start}`
    }
    if (filter.end) {
        url = `${url}&dtFinal=${filter.end}`
    }
    return new Promise(
        function (resolve, reject) {
            Vue.http.get(url).then(res => {
                resolve(res.body);
            }, err => {
                reject({
                    error: true,
                    msg: err
                });
            });
        }
    );
}

function getGeneralRegister () {
    let url = config.api + `/v1/dashboard/getCadastroGeral.php`

    return new Promise (
        function (resolve, reject) {
            Vue.http.get(url).then(res => {
                resolve(res.body)
            }, err => {
                reject ({
                    error: true,
                    msg: err
                })
            })
        }
    )
}

function getSalesByPaymentMethods (loggedId, filter) {
    let url = config.api + `/v1/dashboard/vendasPorFormaDePagamento.php?id_ticketoffice_user=${loggedId}`
    
    if (filter.start) {
        url = `${url}&dtInicial=${filter.start}`
    }
    if (filter.end) {
        url = `${url}&dtFinal=${filter.end}`
    }
    return new Promise(
        function (resolve, reject) {
            Vue.http.get(url).then(res => {
                resolve(res.body);
            }, err => {
                reject({
                    error: true,
                    msg: err
                });
            });
        }
    );
}

function getSumaryChannel (filter) {
    let url = config.api + `/v1/dashboard/getCanalResumido`

    return new Promise(
        function (resolve, reject) {
            Vue.http.get(url).then(res => {
                resolve(res.body);
            }, err => {
                reject({
                    error: true,
                    msg: err
                });
            });
        }
    );
}


function getSalesMonthByMonth (filter) {
    let url = config.api + `/v1/dashboard/vendaAnoMesaMes.php?`

    if (filter.start) {
        url = `${url}anoInicio=${filter.start.split("-", 1)}`
    }
    if (filter.end) {
        url = `${url}&anoFim=${filter.end.split("-", 1)}`
    } else {
        url = `${url}&anoFim=${filter.start.split("-", 1)}`
    }

    return new Promise(
        function (resolve, reject) {
            Vue.http.get(url).then(res => {
                resolve(res.body);
            }, err => {
                reject({
                    error: true,
                    msg: err
                });
            });
        }
    );
}

function getSalesForCity (filter) {
    let url = config.api + `/v1/dashboard/pedidosPorCidade?codigoAcesso=${filter.code}`

    if (filter.start) {
        url = `${url}&dtInicial=${filter.start}`
    }
    if (filter.end) {
        url = `${url}&dtFinal=${filter.end}`
    }
    return new Promise(
        function (resolve, reject) {
            Vue.http.get(url).then(res => {
                resolve(res.body);
            }, err => {
                reject({
                    error: true,
                    msg: err
                });
            });
        }
    );
}
function getPush (filter) {
    let url = config.api + `/v1/dashboard/dadospush?code=${filter.code}`

    if (filter.start) {
        url = `${url}&dtInicial=${filter.start}`
    }
    if (filter.end) {
        url = `${url}&dtFinal=${filter.end}`
    }
    return new Promise(
        function (resolve, reject) {
            Vue.http.get(url).then(res => {
                resolve(res.body);
            }, err => {
                reject({
                    error: true,
                    msg: err
                });
            });
        }
    );
}

function getEmployees (filter) {
    let url = config.api + `/v1/dashboard/quantidadeDeFuncionario?codigoAcesso=${filter.code}`

    if (filter.code === 'TODOS') { 
        url = config.api + '/v1/dashboard/quantidadeDeFuncionario?codigoAcesso'
    }

    return new Promise(
        function (resolve, reject) {
            Vue.http.get(url).then(res => {
                resolve(res.body);
            }, err => {
                reject({
                    error: true,
                    msg: err
                });
            });
        }
    );
}

function getEprepag () {
    let url = config.api + `/v1/integrations/eprepag/saldo`

    return new Promise(
        function (resolve, reject) {
            Vue.http.get(url).then(res => {
                resolve(res.body)
            }, err => {
                reject({
                    error: true,
                    msg: err
                })
            })
        }
    )
}

function getGeneralSalesAndRevenue (filter) {
    let url = config.api + `/v1/dashboard/getVendasGerais?`

    if (filter.start) {
        url = `${url}dtInicial=${filter.start.replaceAll('-', '')}`
    }

    if (filter.end) {
        url = `${url}&dtFinal=${filter.end.replaceAll('-', '')}`
    }

    return new Promise(
        function (resolve, reject) {
            Vue.http.get(url).then(res => {
                resolve(res.body);
            }, err => {
                reject({
                    error: true,
                    msg: err
                });
            });
        }
    );
}

function getRankingOfSites(loggedId, filter) {
    let url = config.api + `/v1/dashboard/rankingSites?loggedId=${loggedId}`;
    if (filter.start) {
        url = `${url}&dtInicial=${filter.start}`
    }
    if (filter.end) {
        url = `${url}&dtFinal=${filter.end}`
    }
    return new Promise(
        function (resolve, reject) {
            Vue.http.get(url).then(res => {
                resolve(res.body);
            }, err => {
                reject({
                    error: true,
                    msg: err
                });
            });
        }
    );
}

function getRankingOfEvents(loggedId, filter) {
    let url = config.api + `/v1/dashboard/rankingEventos?loggedId=${loggedId}`;
    if (filter.start) {
        url = `${url}&dtInicial=${filter.start}`
    }
    if (filter.end) {
        url = `${url}&dtFinal=${filter.end}`
    }
    return new Promise(
        function (resolve, reject) {
            Vue.http.get(url).then(res => {
                resolve(res.body);
            }, err => {
                reject({
                    error: true,
                    msg: err
                });
            });
        }
    );
}

function getRankingOfCompanies(loggedId, filter) {
    let url = config.api + `/v1/dashboard/rankingEmpresas?loggedId=${loggedId}`;
    if (filter.start) {
        url = `${url}&dtInicial=${filter.start}`
    }
    if (filter.end) {
        url = `${url}&dtFinal=${filter.end}`
    }
    return new Promise(
        function (resolve, reject) {
            Vue.http.get(url).then(res => {
                resolve(res.body);
            }, err => {
                reject({
                    error: true,
                    msg: err
                });
            });
        }
    );
}

function getFacebookAcessos() {
    let url = 'https://graph.facebook.com/v14.0/me?fields=fan_count&access_token=EAAPm9JYOLcsBAAwkqJNVS3Iw7eQd3W5KQkZA3cr2IAt6Fsp6ZB2cqReaLQaSMLAoh6kJEzSpZBFpx54OYcdsodk6ospkMN9kS6biVjYPSaAARxElfYjZAZCcaaPYeWClFzhxTdBLhRTPIMSeiAE1PzgfKdQ0iJ7K66r1S2KT6JSJLe5prDnr6bqP0MrRWRcDPNnR4hw8drGcPi40xzT6O'
    return doCallApi('get', url) 
}

function getMeta() {
    let url = config.api + '/v1/dashboard/meta'

    return new Promise(
        function (resolve, reject) {
            Vue.http.get(url).then(res => {
                resolve(res.body);
            }, err => {
                reject({
                    error: true,
                    msg: err
                });
            });
        }
    )
}

function getVendasPorCanal(filter) {
    let url = `${config.api}/v1/dashboard/vendasporcanal.php?id_ticketoffice_user=87A60C38-3390-4493-9D76-A1DCFC608CA5`
    if (filter.start) {
        url = `${url}&dtInicial=${filter.start}`
    }
    if (filter.end) {
        url = `${url}&dtFinal=${filter.end}`
    }
    return doCallApi('get', url)
}

function getGoogleUserQtd(filter) {
    let url = `https://bilheteria.me/api/analytics.php?`;
    if (filter.start) {
        url = `${url}&dtInicial=${filter.start}`
    }
    if (filter.end) {
        url = `${url}&dtFinal=${filter.end}`
    }
    return new Promise(
        function (resolve, reject) {
            Vue.http.get(url).then(res => {
                resolve(res.body);
            }, err => {
                reject({
                    error: true,
                    msg: err
                });
            });
        }
    );
}

function closer(loggedId) {
    let url = config.api + `/v1/dashboard/closer?loggedId=${loggedId}`;

    var ret = new Promise(
    function (resolve, reject) {
        Vue.http.get(url).then(res => {
        resolve(res.body);
        }, err => {
        reject({
            error: true,
            msg: err
        });
        });
    }
  );
  return ret;
}
function getDataDashConsultores(loggedId, periodInit, periodEnd,idUser) {
    let url = config.api + `/v1/dashboard/getDataDashConsultores?loggedId=${loggedId}&periodInit=${periodInit}&periodEnd=${periodEnd}&idUser=${idUser}`;

    var ret = new Promise(
    function (resolve, reject) {
        Vue.http.get(url).then(res => {
        resolve(res.body);
        }, err => {
        reject({
            error: true,
            msg: err
        });
        });
    }
  );
  return ret;
}
function getConsultores(loggedId) {
    let url = config.api + `/v1/dashboard/getConsultores?loggedId=${loggedId}`;

    var ret = new Promise(
    function (resolve, reject) {
        Vue.http.get(url).then(res => {
        resolve(res.body);
        }, err => {
        reject({
            error: true,
            msg: err
        });
        });
    }
  );
  return ret;
}
function purchasebyboleto(loggedId, id_evento, id_apresentacao, date, hour, periodtype, customPeriodInit, customPeriodEnd) {
    let url = config.api + `/v1/dashboard/purchaseboleto?loggedId=${loggedId}&id_evento=${id_evento}&id_apresentacao=${id_apresentacao}&date=${date}&hour=${hour}&periodtype=${periodtype}&customPeriodInit=${customPeriodInit}&customPeriodEnd=${customPeriodEnd}`;

    var ret = new Promise(
    function (resolve, reject) {
        Vue.http.get(url).then(res => {
        resolve(res.body);
        }, err => {
        reject({
            error: true,
            msg: err
        });
        });
    }
  );
  return ret;
}
function purchasebychannel(loggedId, id_evento, id_apresentacao, date, hour, periodtype, customPeriodInit, customPeriodEnd) {
    let url = config.api + `/v1/dashboard/purchasebychannel?loggedId=${loggedId}&id_evento=${id_evento}&id_apresentacao=${id_apresentacao}&date=${date}&hour=${hour}&periodtype=${periodtype}&customPeriodInit=${customPeriodInit}&customPeriodEnd=${customPeriodEnd}`;

    var ret = new Promise(
    function (resolve, reject) {
        Vue.http.get(url).then(res => {
        resolve(res.body);
        }, err => {
        reject({
            error: true,
            msg: err
        });
        });
    }
  );
  return ret;
}
function purchasebypaymenttype(loggedId, id_evento, id_apresentacao, date, hour, periodtype, customPeriodInit, customPeriodEnd) {
    let url = config.api + `/v1/dashboard/purchasebypaymenttype?loggedId=${loggedId}&id_evento=${id_evento}&id_apresentacao=${id_apresentacao}&date=${date}&hour=${hour}&periodtype=${periodtype}&customPeriodInit=${customPeriodInit}&customPeriodEnd=${customPeriodEnd}`;

    var ret = new Promise(
    function (resolve, reject) {
        Vue.http.get(url).then(res => {
        resolve(res.body);
        }, err => {
        reject({
            error: true,
            msg: err
        });
        });
    }
  );
  return ret;
}
function purchasebytimetable(loggedId, id_evento, id_apresentacao, date, hour, periodtype, customPeriodInit, customPeriodEnd) {
    let url = config.api + `/v1/dashboard/purchasebytimetable?loggedId=${loggedId}&id_evento=${id_evento}&id_apresentacao=${id_apresentacao}&date=${date}&hour=${hour}&periodtype=${periodtype}&customPeriodInit=${customPeriodInit}&customPeriodEnd=${customPeriodEnd}`;

    var ret = new Promise(
    function (resolve, reject) {
        Vue.http.get(url).then(res => {
        resolve(res.body);
        }, err => {
        reject({
            error: true,
            msg: err
        });
        });
    }
  );
  return ret;
}
function purchaseoccupation(loggedId, id_evento, id_apresentacao, date, hour, periodtype, customPeriodInit, customPeriodEnd) {
    let url = config.api + `/v1/dashboard/purchaseoccupation?loggedId=${loggedId}&id_evento=${id_evento}&id_apresentacao=${id_apresentacao}&date=${date}&hour=${hour}&periodtype=${periodtype}&customPeriodInit=${customPeriodInit}&customPeriodEnd=${customPeriodEnd}`;

    var ret = new Promise(
    function (resolve, reject) {
        Vue.http.get(url).then(res => {
        resolve(res.body);
        }, err => {
        reject({
            error: true,
            msg: err
        });
        });
    }
  );
  return ret;
}
function purchasevalues(loggedId, id_evento, id_apresentacao, date, hour, periodtype, customPeriodInit, customPeriodEnd) {
    let url = config.api + `/v1/dashboard/purchasevalues?loggedId=${loggedId}&id_evento=${id_evento}&id_apresentacao=${id_apresentacao}&date=${date}&hour=${hour}&periodtype=${periodtype}&customPeriodInit=${customPeriodInit}&customPeriodEnd=${customPeriodEnd}`;

    var ret = new Promise(
    function (resolve, reject) {
        Vue.http.get(url).then(res => {
        resolve(res.body);
        }, err => {
        reject({
            error: true,
            msg: err
        });
        });
    }
  );
  return ret;
}


function doCallApi (method = 'get', url) {
   return new Promise(
        function (resolve, reject) {
            Vue.http[method](url).then(res => {
                resolve(res.body);
            }, err => {
                reject({
                    error: true,
                    msg: err
                });
            });
        }
    );
}