<template>
<div :class="{'col-12 col-lg-3 col-sm-6': size == 'large', 'col-10 col-md-6 col-lg-2' : size == 'small', 'col-12 col-sm-4 col-lg-2': !size}">
  <div class="card" :style="{'min-height': showCustomLoad ? '105px': ''}">
    <div v-if="showCustomLoad && !isLoading" style="height: 100%;align-items: center;display: flex;">
      <half-circle-spinner :size="60" color="#ddd"></half-circle-spinner>
    </div>
    <div v-else class="card-body-small p-3 text-center">
      <div class="text-right" :class="{'text-green': status == 'increase', 'text-red': status == 'decrease'}">

        {{percentage !== '' ? percentage : '&nbsp;'}}

        <i class="fe fe-chevron-up" v-show="status == 'increase'"></i>
        <i class="fe fe-chevron-down" v-show="status == 'decrease'"></i>
      </div>
      <div class="h1 m-0 text-size-card-info">{{label}} {{ label ? parseFloat(value).toLocaleString('pt-br', {minimumFractionDigits: 2}) : formatNumber.format(value) }}</div>
      <div class="text-muted mt-2">{{title}}</div>
    </div>
  </div>
</div>
</template>

<script>
import { HalfCircleSpinner } from 'epic-spinners'

export default {
  name: "NewCardInfo",
  components: { HalfCircleSpinner },
  props: ['title', 'value', 'percentage', 'status', 'size', 'showCustomLoad', 'isLoading', 'label'],
  data () {
    return {
    }
  },
  computed: {
    formatNumber () {
      return Intl.NumberFormat('pt-BR', { maximumSignificantDigits: 10 })
    }
  }
}
</script>

<style lang="scss">
.text-size-card-info {
  font-size: 1.2rem !important;
}
.card {
  .card-body-small {
    height: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
</style>
