<template>
<div class="card">
    <div class="card-body-large p-3 text-center" style="min-height: 105px;">
    <half-circle-spinner v-if="isLoading" :size="60" color="#ddd"></half-circle-spinner>
        <div v-else>
        <div class="h1 m-0 text-size-card-info">{{ formatNumber.format(value) }}</div>
        <div class="text-muted mb-4 mt-2">{{title}}</div>
        </div>
    </div>
</div>
</template>

<script>
import {
  HalfCircleSpinner
} from 'epic-spinners'

export default {
  name: "LargeCardInfo",
  props: ['title', 'value', 'isLoading'],
  components: { HalfCircleSpinner },
  data () {
    return {
    }
  },
  computed: {
    formatNumber () {
      return Intl.NumberFormat('pt-BR', { maximumSignificantDigits: 10 })
    }
  }
};
</script>

<style lang="scss">
.text-size-card-info {
  font-size: 1.2rem !important;
}
.card {
  .card-body-large {
    height: 120px;
  }
}
</style>
